<template>
  <SiteTemplateV2 v-if="clubVersion === 'v2'">
    <MembersComponentV2 />
  </SiteTemplateV2>

  <SiteTemplate v-else>
    <MembersComponent />
  </SiteTemplate>
</template>

<script>
import SiteTemplate from "@/templates/SiteTemplate.vue";
import MembersComponent from "@/components/MembersComponent.vue";
import MembersComponentV2 from "@/components/V2/MembersComponent.vue";
import SiteTemplateV2 from "@/templates/SiteTemplateV2.vue";

export default {
  components: {
    SiteTemplate,
    SiteTemplateV2,
    MembersComponent,
    MembersComponentV2,
  },

  data() {
    return {
      client: {
        width: 0,
      },
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.client.width = window.innerWidth;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.container-grid {
  display: grid;
  grid-template-columns: 100px 1fr;
}

.container-grid2 {
  width: 100%;
}

.slidebarMobile {
  float: right;
  padding-top: 20px;
  position: relative;
  right: 0.6em;
}

.containerx {
  width: 98%;
}
</style>
